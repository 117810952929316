(function(WebAccounts, undefined) {
    WebAccounts.removeLoader = function () {
        WebAccounts.removeClass('#loader-wrapper','visible');
    };

    WebAccounts.removeClass = function(element, classToBeRemoved) {
        if(!element){
            return null;
        }
            
        
        if(typeof(element) === 'string') {
            element = document.querySelectorAll(element);
        } else if(element.tagName) {
            element = [element];
        }

        var reg = new RegExp('(^| )' + classToBeRemoved + '($| )','g');
        
        for(var i = 0; i < element.length; i++) {
            element[i].className = element[i].className.replace(reg,' ');
        }
    };
})(window.WebAccounts = window.WebAccounts || {});

//Polyfill for IE9+
// (function () {

//   if ( typeof window.CustomEvent === "function" ) return false;

//   function CustomEvent ( event, params ) {
//     params = params || { bubbles: false, cancelable: false, detail: undefined };
//     var evt = document.createEvent( 'CustomEvent' );
//     evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
//     return evt;
//    }

//   CustomEvent.prototype = window.Event.prototype;

//   window.CustomEvent = CustomEvent;
// })();

// (function (WebAccounts, undefined) {
//     'use strict';

//     WebAccounts.refs = document.head;

//     WebAccounts.StyleSheet = function(name, href, before, media, eventTrigger) {
//         this.media = media;
//         this.eventTrigger = eventTrigger;
//         this.name = name;

//         var DOM = window.document.createElement("link");
//         DOM.rel = 'stylesheet';
//         DOM.type = 'text/css';
//         DOM.href = href + '?str=' + Math.random().toString(36).substring(7);;
//         // DOM.href = 'aaaaa.css';
//         DOM.media = "only x";
//         DOM.id = name + '-style';

//         this.before = (typeof before == 'array' && before.length != 0)? before : [];

//         this.getDOM = function() {
//             return DOM;
//         }

//         this.validForInsert = function() {
//             if( before.length != 0 ) {
//                 var i = 0;
//                 for(i = 0; i < before.length; i++) {
//                     if(window.document.getElementById(before[i] + '-style') === null)
//                         continue; 


//                     before.splice(i, 1);
//                 }
//             }

//             if(before.length === 0) {
//                 return true;
//             }

//             return false;
//         }


//         this.insertIntoHead = function() {

//             console.log(DOM);
//             document.head.appendChild(DOM);
//         }


//         this.trigger = function() {
//             DOM.media = this.media;

//             if(this.eventTrigger === undefined)
//                 return;

//             var event = new CustomEvent(eventTrigger, { detail: this });

//             document.dispatchEvent(event);
//         };
//     }
    
//     //Wait until body is defined before injecting link.
//     WebAccounts.runOnBodyReady = function(styleSheet, callback) {
//         if(window.document.body !== null &&
//             styleSheet.validForInsert()) {
//             return callback();
//         }

//         setTimeout(function() {
//             WebAccounts.runOnBodyReady(styleSheet, callback);
//         });
//     };

//     WebAccounts.onloadDefinedCss = function(styleSheet, callback) {
//         var resolvedHref = styleSheet.href,
//                 i = window.document.styleSheets.length;

//             while( i-- ) {
//                 if( window.document.styleSheets[i].href === resolvedHref) {
//                     return callback();
//                 }
//             }

//             setTimeout(function() {
//                 WebAccounts.onloadDefinedCss(styleSheet, callback);
//             });
//     };

//     WebAccounts.asyncCSS = function(styleSheetName, href, before, media, triggerEventName) {
//         var styleSheet = new WebAccounts.StyleSheet(styleSheetName, href, before, media, triggerEventName);

//         WebAccounts.runOnBodyReady( styleSheet, function() {
//             styleSheet.insertIntoHead();
//             styleSheet.trigger();
//         });


//         // var loadCallback = function() {
//         //     if(styleSheet.getDOM().addEventListener) {
//         //         styleSheet.getDOM().removeEventListener("load", loadCallback);
//         //     }

//         //     styleSheet.getDOM().media = media || "all";
//         // };

//         // if(styleSheet.getDOM().addEventListener) {
//         //     styleSheet.getDOM().addEventListener("load", loadCallback);
//         // }

//         // WebAccounts.onloadDefinedCss(styleSheet, loadCallback);

//         return styleSheet;
//     };

//     document.addEventListener('marto',function(event) {
//         console.log('hello');
//     });

// })(window.WebAccounts = window.WebAccounts || {});